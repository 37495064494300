import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SpaceEn from "assets/images/space-en.png";
import SpaceEnMobile from "assets/images/space-en-mobile.png";
import SpaceViMobile from "assets/images/space-vi.png";
import SpaceVi from "assets/images/space-vi.png";
import LearningEn from "assets/images/learning-en.png";
import LearningEnMobile from "assets/images/learning-en-mobile.png";
import LearningVi from "assets/images/learning-vi.png";
import LearningViMobile from "assets/images/learning-vi.png";
import { useTranslation } from "react-i18next";
import { useLangContext } from "context/LangContext";
import useResponsive from "hooks/useResponsive";

const Smart2 = () => {
  const { t } = useTranslation();
  const { language } = useLangContext();
  const { isMobile } = useResponsive();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          <span style={{ color: "#0C224F" }}>{t("key-features-smart")}</span>
        </h3>
        <FlexBox data-aos="fade-up">
          <div className="description left">
            <h4>{t("key-features-smart1")}</h4>
            {isMobile ? (
              <>
                {language == "vi" && <img src={SpaceViMobile}></img>}
                {language == "en" && <img src={SpaceEnMobile}></img>}
              </>
            ) : (
              <>
                {language == "vi" && <img src={SpaceVi}></img>}
                {language == "en" && <img src={SpaceEn}></img>}
              </>
            )}
          </div>
          <div className="description">
            <h4>{t("key-features-smart2")}</h4>
            {isMobile ? (
              <>
                {language == "vi" && <img src={LearningViMobile}></img>}
                {language == "en" && <img src={LearningEnMobile}></img>}
              </>
            ) : (
              <>
                {language == "vi" && <img src={LearningVi}></img>}
                {language == "en" && <img src={LearningEn}></img>}
              </>
            )}
          </div>
        </FlexBox>
      </div>
    </Section>
  );
};

export default Smart2;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 100px 24px;
  @media screen and (max-width: 1024px) {
    padding: 50px 24px;
  }

  & > .wrapper {
    width: 100%;
    max-width: 1480px;
    margin: auto;
    & > h3 {
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      margin-bottom: 80px;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
      }
      @media screen and (max-width: 660px) {
        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      color: #141414;
      font-size: 26px;
      line-height: 43px;
      margin-bottom: 80px;
    }
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    width: 100%;
    max-width: 734px;
    margin: auto;
  }
  @media screen and (max-width: 660px) {
  }
  & > .description {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    @media screen and (max-width: 1024px) {
      width: 100%;
      border: 2px dashed #666;
      margin-bottom: 20px;
      border-radius: 14px;
    }
    @media screen and (max-width: 660px) {
      padding: 20px 10px;
    }
    & > h4 {
      color: #0c224f;
      text-align: center;
      font-size: 26px;
      font-weight: 800;
      line-height: 30px; /* 115.385% */
      letter-spacing: -0.078px;
      text-transform: uppercase;
      white-space: pre-line;
      margin-bottom: 80px;
      @media screen and (max-width: 1024px) {
        max-width: 418px;
        margin-bottom: 30px;
      }
      @media screen and (max-width: 660px) {
        font-size: 16px;
        line-height: 22px; /* 137.5% */
        letter-spacing: -0.048px;
        margin-bottom: 20px;
      }
    }
    & > img {
      width: 100%;
      @media screen and (max-width: 1024px) {
        max-width: 557px;
      }
      @media screen and (max-width: 660px) {
        max-width: 227px;
      }
    }
  }
  & > .left {
    border-right: 2px dashed #666;
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 660px) {
    }
  }
`;
