import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import AppFeatures from "assets/images/appfeatures.png";
import AppFeaturesMobile from "assets/images/appfeatures-mobile.png";
import { useTranslation } from "react-i18next";
import useResponsive from "hooks/useResponsive";

const Smart3 = () => {
  const { t } = useTranslation();

  const { isTabletAndMobile } = useResponsive();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          {t("app")}&nbsp;
          <span style={{ color: "#0C224F" }}>{t("features")}</span>
        </h3>
        <FlexBox>
          {isTabletAndMobile ? (
            <img src={AppFeaturesMobile}></img>
          ) : (
            <img src={AppFeatures}></img>
          )}
          <div className="description">
            <h4>{t("our-app-func")}</h4>
            <div className="list">
              <h4>{t("our-app-func-title1")}</h4>
              <div className="flexBox">
                <span>·</span>
                <p>{t("our-app-func-text1")}</p>
              </div>
            </div>
            <div className="list">
              <h4>{t("our-app-func-title2")}</h4>
              <div className="flexBox">
                <span>·</span>
                <p>{t("our-app-func-text2-1")}</p>
              </div>
              <div className="flexBox">
                <span>·</span>
                <p>{t("our-app-func-text2-2")}</p>
              </div>
              <div className="flexBox">
                <span>·</span>
                <p>{t("our-app-func-text2-3")}</p>
              </div>
            </div>
            <div className="list">
              <h4>{t("our-app-func-title3")}</h4>
              <div className="flexBox">
                <span>·</span>
                <p>{t("our-app-func-text3-1")}</p>
              </div>
            </div>
            <div className="list">
              <h4>{t("our-app-func-title4")}</h4>
              <div className="flexBox">
                <span>·</span>
                <p>{t("our-app-func-text4-1")}</p>
              </div>
              <div className="flexBox">
                <span>·</span>
                <p>{t("our-app-func-text4-2")}</p>
              </div>
            </div>
          </div>
        </FlexBox>
      </div>
    </Section>
  );
};

export default Smart3;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 100px 24px;
  @media screen and (max-width: 1024px) {
    padding: 50px 24px;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
  }
  @media screen and (max-width: 660px) {
  }
  & > .wrapper {
    width: 100%;
    max-width: 733px;
    margin: auto;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
    }
    & > h3 {
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      margin-bottom: 80px;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
        white-space: normal;
      }
      @media screen and (max-width: 660px) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
    & > p {
      color: #141414;
      font-size: 26px;
      line-height: 43px;
      margin-bottom: 80px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
    }
  }
`;

const FlexBox = styled.div`
  & > img {
    display: block;
    width: 100%;
    margin: 0 auto 40px auto;
    @media screen and (max-width: 660px) {
      margin: 0 auto 20px auto;
    }
  }
  & > .description {
    & > h4 {
      text-align: center;
      color: #0c224f;
      font-size: 26px;
      font-weight: 700;
      text-transform: capitalize;
      margin-bottom: 40px;
      white-space: pre-line;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-width: 660px) {
        margin-bottom: 20px;
      }
    }
    & > .list {
      margin-bottom: 40px;
      @media screen and (max-width: 660px) {
        margin-bottom: 20px;
      }
      & > h4 {
        color: #141414;
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 10px;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 660px) {
        }
      }
      & > .flexBox {
        display: flex;
        align-items: flex-start;
        & > span {
          margin-left: 5px;
          margin-right: 10px;
          color: #666;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
        }
        & > p {
          color: #666;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 133.333% */
          margin-bottom: 10px;
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
          @media screen and (max-width: 660px) {
          }
        }
      }
      & > ul {
        /* list-style: disc; */
        & > li {
          color: #666;
          font-size: 18px;
          font-weight: 400;
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
          @media screen and (max-width: 660px) {
          }
        }
      }
    }
  }
`;
