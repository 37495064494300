import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Key1 from "assets/images/key1.png";
import Key2 from "assets/images/key2.png";
import Key3 from "assets/images/key3.png";
import Key4 from "assets/images/key4.png";
import Key5 from "assets/images/key5.png";
import Key6 from "assets/images/key6.png";
import { useTranslation } from "react-i18next";

const Ttest2 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">{t("key-features")}</h3>

        <GridBox>
          <div className="list" data-aos="fade-up">
            <div className="number">01</div>
            <img src={Key1}></img>
            <div className="description">
              <h4>{t("key-features-title1")}</h4>
              <p>{t("key-features-text1")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <div className="number">02</div>
            <img src={Key2}></img>
            <div className="description">
              <h4>{t("key-features-title2")}</h4>
              <p>{t("key-features-text2")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <div className="number">03</div>
            <img src={Key3}></img>
            <div className="description">
              <h4>{t("key-features-title3")}</h4>
              <p>{t("key-features-text3")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <div className="number">04</div>
            <img src={Key4}></img>
            <div className="description">
              <h4>{t("key-features-title4")}</h4>
              <p>{t("key-features-text4")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <div className="number">05</div>
            <img style={{ marginBottom: "20px" }} src={Key5}></img>
            <div className="description">
              <h4>{t("key-features-title5")}</h4>
              <p>{t("key-features-text5")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <div className="number">06</div>
            <img src={Key6}></img>
            <div className="description">
              <h4>{t("key-features-title6")}</h4>
              <p>{t("key-features-text6")}</p>
            </div>
          </div>
        </GridBox>
      </div>
    </Section>
  );
};

export default Ttest2;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  background-color: #0c224f;
  padding: 0 24px;
  & > .wrapper {
    width: 100%;
    max-width: 1120px;
    z-index: 2; /* wrapper 요소의 z-index 설정 */
    position: relative; /* z-index가 적용되도록 position 설정 */
    margin: 0 auto 40px auto;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
    }

    & > h3 {
      color: white;
      font-size: 54px;
      font-weight: 700;
      margin-bottom: 80px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 100px;
      @media screen and (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
        white-space: normal;
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      color: white;
      font-size: 26px;
      line-height: 43px;
      text-align: center;
      margin-bottom: 40px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 660px) {
      }
    }
    & > .imgWrapper {
      width: 100%;
      max-width: 1177px;
      margin: auto;
      h3 {
        color: white;
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 15px;
      }
    }
  }
`;

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개의 동일한 너비의 컬럼 생성 */
  gap: 20px; /* 셀 간격 */
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 3개의 동일한 너비의 컬럼 생성 */
  }
  @media screen and (max-width: 660px) {
    grid-template-columns: repeat(1, 1fr); /* 3개의 동일한 너비의 컬럼 생성 */
    gap: 10px; /* 셀 간격 */
  }
  & > .list {
    border-radius: 20px;
    background: #0f2f71;
    overflow: hidden;
    position: relative;
    height: 384px;
    padding: 20px;
    @media screen and (max-width: 660px) {
      height: auto;
    }
    & > .number {
      width: 100%;
      text-align: right;
      color: #3c68c1;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 38px;
      @media screen and (max-width: 660px) {
        margin-bottom: 0px;
      }
    }
    & > img {
      width: 100%;
      max-width: 80px;
      display: block;
      margin: 0 auto 20px auto;
    }
    & > .description {
      color: white;
      & > h4 {
        color: #fff;
        font-size: 26px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 660px) {
        }
      }
      & > p {
        font-size: 18px;
        text-align: center;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 660px) {
        }
      }
    }
  }
`;
