import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KGOLogo from "assets/images/kgo_black_logo.png";
import MenuBtn from "assets/images/menu_btn.png";
import styled from "styled-components";
import useResponsive from "hooks/useResponsive";
import MenuModal from "components/modal/menumodal/MenuModal";
import { useLangContext } from "context/LangContext";
import { useTranslation } from "react-i18next";

const Header = ({ handleNavClick }) => {
  const location = useLocation();
  const { language, changeLanguage } = useLangContext();

  const isMainPage = location.pathname === "/" ? "true" : "false";
  const navigate = useNavigate();
  const { isTabletAndMobile, isDesktop } = useResponsive();

  //useState 초기화
  const [showMenuModal, setShowMenuModal] = useState(false);

  //마이페이지 모달 토글
  const openMenuModal = () => {
    setShowMenuModal(true);
  };
  const closeMenuModal = () => {
    setShowMenuModal(false);
  };

  const clickHandler = (lang) => {
    changeLanguage(lang);
  };

  const movePage = (page) => {
    const targetPath = `/${page}`;

    if (location.pathname === targetPath) {
      // 같은 페이지일 경우
      window.location.href = targetPath;
    } else {
      // 다른 페이지일 경우
      navigate(targetPath);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <NavWrapper $ismainpage={isMainPage}>
        <div className="navContents">
          <div
            id="logo"
            onClick={() => {
              navigate("/");
            }}
          >
            <a>
              <img src={KGOLogo} alt="logo"></img>
            </a>
          </div>
          <CenterBox>
            <div onClick={() => movePage("about-kgo")}>
              {t("menu-aboutkgo")}
            </div>
            <div onClick={() => movePage("#ourservices")}>
              {t("menu-ourservices")}
            </div>
          </CenterBox>
          <RightBox>
            <LanguageSelect>
              <div
                onClick={() => clickHandler("en")}
                style={{ fontWeight: language == "en" ? "700" : "300" }}
              >
                ENG
              </div>
              <div>|</div>
              <div
                onClick={() => clickHandler("vi")}
                style={{ fontWeight: language == "vi" ? "700" : "300" }}
              >
                VND
              </div>
            </LanguageSelect>
            <div className="menu" onClick={openMenuModal}>
              <img width={44} src={MenuBtn} alt="menu"></img>
            </div>
          </RightBox>
        </div>
      </NavWrapper>
      <MenuModal open={showMenuModal} close={closeMenuModal}></MenuModal>
    </>
  );
};

export default Header;

// background-color: ${(props) => props.$ismainpage == "false" && "white"};
const NavWrapper = styled.nav`
  position: fixed;
  width: 100%;
  min-width: 320px;
  height: 80px;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.40); */
  background: white;
  @media screen and (max-width: 660px) {
    height: 53px;
  }
  & > .navContents {
    width: 100%;
    margin: 0 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1024px) {
      margin: 0 60px;
    }
    @media screen and (max-width: 660px) {
      margin: 0 20px;
    }
    & > #logo {
      cursor: pointer;
      user-select: none; /* 텍스트 선택 방지 */
      & > a > img {
        width: 126px;
        @media screen and (max-width: 660px) {
          width: 80px;
        }
      }
    }
  }
`;

const CenterBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  & > div {
    color: #141414;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    text-transform: uppercase;
    margin-right: 50px;
    cursor: pointer;
    &:last-child {
      margin: 0;
    }
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const RightBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & > .menu {
    cursor: pointer;
  }
`;

const LanguageSelect = styled.div`
  margin-right: 50px;
  display: flex;
  user-select: none; /* 텍스트 선택 방지 */

  & > div {
    font-size: 16px;
    color: #141414;
    margin-left: 10px;
    cursor: pointer;
  }
  @media screen and (max-width: 660px) {
    display: none;
  }
`;
