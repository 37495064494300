import React, { useEffect, useRef } from "react";
import styled from "styled-components";
// import SmartBg from "assets/images/smart_common_bg.png";
import SmartBg from "assets/images/smart_common_bg.webp";
import { useTranslation } from "react-i18next";
import Smart1 from "./components/Smart1";
import Smart2 from "./components/Smart2";
import Smart3 from "./components/Smart3";
import Smart4 from "./components/Smart4";

const Smart = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section $bg={SmartBg}>
        <AbsoluteBox>
          <div className="title" data-aos="fade-up">
            {t("smart-digital-classroom")}
          </div>
          <div className="sub-title" data-aos="fade-up">
            {t("smart-digital-classroom-text")}
          </div>
        </AbsoluteBox>
        <div className="coverBox"></div>
      </Section>
      <Smart1></Smart1>
      <Smart2></Smart2>
      <Smart3></Smart3>
      <Smart4></Smart4>
    </>
  );
};

export default Smart;

const Section = styled.section`
  width: 100%;
  height: 100vh;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  & > .coverBox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all.3s;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 1024px) {
    height: 70vh;
  }
  @media screen and (max-width: 660px) {
    height: 40vh;
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1440px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  & > .title,
  & > .sub-title {
    opacity: 0;
    transform: translateY(40px);
    transition: all 1s ease-out;
  }

  & > .title.animate,
  & > .sub-title.animate {
    opacity: 1;
    transform: translateY(0);
  }

  & > .title {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 68px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 auto 20px auto;
    @media screen and (max-width: 1024px) {
      font-size: 54px;
    }
    @media screen and (max-width: 660px) {
      max-width: 327px;
      font-size: 26px;
    }
  }
  & > .sub-title {
    text-align: center;
    color: #fff;
    font-size: 34px;
    margin: 0 auto 100px auto;
    & > span {
      color: #0652cc;
      font-weight: 700;
    }
    @media screen and (max-width: 1024px) {
      font-size: 26px;
    }
    @media screen and (max-width: 660px) {
      max-width: 327px;
      font-size: 16px;
      margin-bottom: 0px;
    }
  }
`;
