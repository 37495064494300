import React from "react";
import styled from "styled-components";
import WelcomeImg2 from "assets/images/welcome2.png";
import WelcomeImg2Tablet from "assets/images/welcome2-tablet.png";
import { useTranslation } from "react-i18next";
import useResponsive from "hooks/useResponsive";

const Welcome2 = () => {
  const { t } = useTranslation();

  const { isTablet } = useResponsive();

  return (
    <Section>
      <div className="wrapper">
        <div className="imgWrapper">
          {isTablet ? (
            <img src={WelcomeImg2Tablet}></img>
          ) : (
            <img src={WelcomeImg2}></img>
          )}
        </div>
        <div className="textWrapper">
          <h3 data-aos="fade-up">{t("legacy")}</h3>
          <p data-aos="fade-up">{t("legacy-text")}</p>
        </div>
      </div>
    </Section>
  );
};

export default Welcome2;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  & > .wrapper {
    background-color: #0c224f;
    margin-bottom: -5px;

    width: 100%;
    display: flex;

    align-items: center;
    @media screen and (max-width: 1024px) {
      display: block;
    }

    & > .imgWrapper {
      width: 42.5%;
      height: 100%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      @media screen and (max-width: 660px) {
        height: auto;
      }

      & > img {
        width: 100%;
        height: 100%;
        min-height: 890px;

        object-fit: fill; /* 수정 */
        @media screen and (max-width: 1024px) {
          min-height: auto;
        }
      }
    }

    & > .textWrapper {
      color: white;
      width: 57.5%;
      padding: 0 100px;
      @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 100px;
      }
      @media screen and (max-width: 660px) {
        padding: 40px 24px;
      }
      & > h3 {
        width: 100%;
        font-size: 54px;
        font-weight: 800;
        margin-bottom: 40px;
        display: flex;
        white-space: nowrap;
        justify-content: center;
        @media screen and (max-width: 1024px) {
          font-size: 30px;
        }
        @media screen and (max-width: 660px) {
          font-size: 20px;
          margin-bottom: 20px;
        }
        & > .line {
          width: 100%;
          height: 1px;
          background-color: white;
          margin-left: 40px;
          margin-bottom: 15px;
        }
      }
      & > p {
        width: 100%;
        max-width: 832px;
        margin: auto;
        font-size: 26px;
        line-height: 43px;
        text-align: center;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
          line-height: 22px;
        }
        @media screen and (max-width: 660px) {
        }
      }
    }
  }
`;
