import { Route, Router, Routes, useLocation } from "react-router-dom";
import "styles/index.css";
import Header from "components/layout/components/Header";
import Footer from "components/layout/components/Footer";
import Ai from "pages/ai/Ai";
import Language from "pages/language/Language";
import Preparatory from "pages/preparatory/Preparatory";
import Smart from "pages/smart/Smart";
import Ttest from "pages/ttest/Ttest";
import Welcome from "pages/welcome/Welcome";
import { LangContextProvider } from "context/LangContext";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import VideoMain from "pages/video-main/VideoMain";
import useScrollRestoration from "hooks/useScrollRestoration";
import styled from "styled-components";

function App() {
  const location = useLocation();
  const isMainPage = location.pathname === "/";

  console.log("isMainPage", isMainPage);

  useScrollRestoration();

  useEffect(() => {
    AOS.init({
      duration: 1200,
      delay: 200,
      once: true,
    });
  }, []);

  return (
    <LangContextProvider>
      <Header />
      <Content>
        <Routes>
          <Route path="/" element={<VideoMain />} />
          <Route path="/about-kgo" element={<Welcome />} />
          <Route path="/language-programs" element={<Language />} />
          <Route path="/t-test" element={<Ttest />} />
          <Route path="/preparatory-programs" element={<Preparatory />} />
          <Route path="/smart-digital-classroom" element={<Smart />} />
          <Route path="/ai-solution" element={<Ai />} />
        </Routes>
      </Content>
      <Footer />
    </LangContextProvider>
  );
}

export default App;

const Content = styled.div`
  padding-top: 80px;

  @media (max-width: 768px) {
    padding-top: 53px;
  }
`;
