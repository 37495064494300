import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Hands from "assets/images/hands.png";
import { useTranslation } from "react-i18next";

const Ai3 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <BackgroundBox $bg={Hands}>
        <div className="textBox">
          <h3 data-aos="fade-up">{t("kgo-aim")}</h3>
        </div>
      </BackgroundBox>
    </Section>
  );
};

export default Ai3;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
  & > .colorText {
    color: #0c224f;
    text-align: center;
    font-size: 54px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const BackgroundBox = styled.div`
  width: 100%;
  height: 815px;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    height: 434px;
  }
  @media screen and (max-width: 660px) {
    height: 256px;
  }
  & > .textBox {
    width: 100%;
    max-width: 549px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1024px) {
      max-width: 331px;
    }
    @media screen and (max-width: 660px) {
      max-width: 209px;
    }
    & > h3 {
      color: #fff;
      font-size: 26px;
      font-weight: 700;
      z-index: 3; /* wrapper 요소의 z-index 설정 */
      position: relative; /* z-index가 적용되도록 position 설정 */
      text-transform: uppercase;
      text-align: center;
      white-space: pre-line;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
      @media screen and (max-width: 660px) {
        font-size: 16px;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(12, 34, 79, 0.5);
    z-index: 0;
  }
`;
