import React, { useEffect, useRef } from "react";
import styled from "styled-components";
// import WelcomeBg from "assets/images/welcome_bg.png";
import WelcomeBg from "assets/images/welcome_bg.webp";
import { useTranslation } from "react-i18next";
import Welcome1 from "./components/Welcome1";
import Welcome2 from "./components/Welcome2";
import Welcome3 from "./components/Welcome3";
import Welcome4 from "./components/Welcome4";
import Welcome5 from "./components/Welcome5";
import Welcome6 from "./components/Welcome6";
import Welcome7 from "./components/Welcome7";
import Welcome8 from "./components/Welcome8";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section $bg={WelcomeBg}>
        <AbsoluteBox>
          <div className="title" data-aos="fade-up">
            {t("about-kgo")}
          </div>
          <div className="sub-title" data-aos="fade-up">
            {t("about-kgo-text")}
          </div>
        </AbsoluteBox>
        <div className="coverBox"></div>
      </Section>
      <Welcome1></Welcome1>
      <Welcome2></Welcome2>
      <Welcome3></Welcome3>
      <Welcome4></Welcome4>
      <Welcome5></Welcome5>
      <Welcome6></Welcome6>
      <Welcome7></Welcome7>
      <Welcome8></Welcome8>
    </>
  );
};

export default Welcome;

const Section = styled.section`
  width: 100%;
  height: 100vh;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  & > .coverBox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all.3s;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 1024px) {
    height: 70vh;
  }
  @media screen and (max-width: 660px) {
    height: 40vh;
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1070px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  & > .title {
    text-align: center;
    color: #fff;
    font-size: 68px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
      font-size: 54px;
    }
    @media screen and (max-width: 660px) {
      font-size: 26px;
    }
  }
  & > .sub-title {
    text-align: center;
    color: #fff;
    font-size: 34px;
    & > span {
      color: #0652cc;
      font-weight: 700;
    }
    @media screen and (max-width: 1024px) {
      font-size: 26px;
    }
    @media screen and (max-width: 660px) {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }
`;
