import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Mission1 from "assets/images/mission1.png";
import Mission2 from "assets/images/mission2.png";
import Mission3 from "assets/images/mission3.png";
import { Trans, useTranslation } from "react-i18next";
import { useLangContext } from "context/LangContext";

const Welcome5 = () => {
  const { t } = useTranslation();
  const { language } = useLangContext();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">{t("mission")}</h3>
        <p data-aos="fade-up">
          <Trans
            i18nKey="mission-text"
            components={{
              strong: <span />,
            }}
          />
        </p>
        <div className="imgWrapper">
          <FlexBox>
            <div className="box">
              <img src={Mission1} data-aos="fade-up" />
              <p data-aos="fade-up">{t("mission-img1")}</p>
            </div>
            <div className="box">
              <img src={Mission2} data-aos="fade-up" />
              <p data-aos="fade-up">{t("mission-img2")}</p>
            </div>
            <div className="box">
              <img src={Mission3} data-aos="fade-up" />
              <p data-aos="fade-up">{t("mission-img3")}</p>
            </div>
          </FlexBox>
        </div>
      </div>
    </Section>
  );
};

export default Welcome5;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0px 24px;
  & > .wrapper {
    width: 100%;
    max-width: 1480px;
    margin: auto;
    z-index: 2; /* wrapper 요소의 z-index 설정 */
    position: relative; /* z-index가 적용되도록 position 설정 */
    margin-bottom: 200px;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
      margin-bottom: 124px;
    }
    @media screen and (max-width: 660px) {
      margin-bottom: 0px;
    }
    & > h3 {
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      letter-spacing: -0.162px;
      margin-top: 200px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
        white-space: normal;
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      color: #141414;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 43px;
      letter-spacing: -0.06px;
      margin-bottom: 40px;
      & > span {
        color: #0c224f;
        font-size: 26px;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: -0.078px;
        @media screen and (max-width: 1024px) {
          font-size: 20px;
        }
        @media screen and (max-width: 660px) {
          font-size: 18px;
        }
      }
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 660px) {
        line-height: 0px;
        margin-bottom: 20px;
      }
    }
    & > .imgWrapper {
      width: 100%;
      max-width: 1177px;
      margin: auto;
    }
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  & > .box {
    width: 325px;

    & > img {
      width: 100%;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    & > p {
      color: #141414;
      text-align: center;
      font-size: 26px;
      font-weight: 400;
      letter-spacing: -0.078px;
      margin-top: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 660px) {
        margin-top: 10px;
      }
    }
  }
`;
