import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Partner1 from "assets/images/partner1.png";
import Partner2 from "assets/images/partner2.png";
import Partner3 from "assets/images/partner3.png";
import Partner4 from "assets/images/partner4.png";
import Partner5 from "assets/images/partner5.png";
import { useTranslation } from "react-i18next";

const Welcome7 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          {t("partner-kgo")} <span style={{ color: "#0C224F" }}>KGO</span>?
        </h3>

        <div className="numberBox">
          <div className="list">
            <img src={Partner1} data-aos="fade-up" />
            <p data-aos="fade-up">
              <span style={{ color: "#0C224F", fontWeight: "700" }}>
                {t("partner1-title")}
              </span>
              <br></br>
              {t("partner1-text")}
            </p>
          </div>
          <div className="list">
            <img src={Partner2} data-aos="fade-up" />
            <p data-aos="fade-up">
              <span style={{ color: "#0C224F", fontWeight: "700" }}>
                {t("partner2-title")}
              </span>
              <br></br>
              {t("partner2-text")}
            </p>
          </div>
          <div className="list">
            <img src={Partner3} data-aos="fade-up" />
            <p data-aos="fade-up">
              <span style={{ color: "#0C224F", fontWeight: "700" }}>
                {t("partner3-title")}
              </span>
              <br></br>
              {t("partner3-text")}
            </p>
          </div>
          <div className="list">
            <img src={Partner4} data-aos="fade-up" />
            <p data-aos="fade-up">
              <span style={{ color: "#0C224F", fontWeight: "700" }}>
                {t("partner4-title")}
              </span>
              <br></br>
              {t("partner4-text")}
            </p>
          </div>
          <div className="list">
            <img src={Partner5} data-aos="fade-up" />
            <p data-aos="fade-up">
              <span style={{ color: "#0C224F", fontWeight: "700" }}>
                {t("partner5-title")}
              </span>
              <br></br>
              {t("partner5-text")}
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Welcome7;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0 24px;
  & > .wrapper {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    z-index: 2; /* wrapper 요소의 z-index 설정 */
    position: relative; /* z-index가 적용되도록 position 설정 */
    margin-bottom: 200px;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
      margin-bottom: 124px;
    }
    @media screen and (max-width: 660px) {
      margin-bottom: 40px;
    }
    & > h3 {
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      margin-top: 200px;
      margin-bottom: 80px;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        font-size: 30px;
        margin-top: 80px;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 660px) {
        font-size: 20px;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

    & > .numberBox {
      width: 100%;
      margin: auto;
      @media screen and (max-width: 1024px) {
        max-width: 431px;
      }
      & > .list {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 60px;
        @media screen and (max-width: 1024px) {
          margin-bottom: 20px;
        }
        & > img {
          margin-top: -20px;
          margin-right: 15px;
          @media screen and (max-width: 1024px) {
            margin-top: -10px;
            width: 48px;
          }
          @media screen and (max-width: 660px) {
          }
        }
        & > p {
          color: #141414;
          font-size: 26px;
          width: 100%;
          max-width: 904px;
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
          @media screen and (max-width: 660px) {
          }
        }
      }
    }
  }
`;
