import React from "react";
import styled from "styled-components";
import Welcome3En from "assets/images/welcome3-en.png";
import Welcome3Vi from "assets/images/welcome3-vi.png";
import Welcome3Mobile from "assets/images/welcome3-mobile.png";
import YellowCircle from "assets/images/yellow-sm-circle.png";
import BlueCircle from "assets/images/blue-sm-circle.png";
import GreenCircle from "assets/images/green-sm-circle.png";
import PinkCircle from "assets/images/pink-sm-circle.png";
import { useTranslation } from "react-i18next";
import { useLangContext } from "context/LangContext";
import useResponsive from "hooks/useResponsive";

const Welcome3 = () => {
  const { t } = useTranslation();

  const { isTabletAndMobile } = useResponsive();

  const { language } = useLangContext();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          {t("university-education")}
          <br></br>
          <span style={{ color: "#0C224F" }}>{t("performance")}</span>
        </h3>
        {isTabletAndMobile ? (
          <div className="imgBox">
            <img data-aos="zoom-in" src={Welcome3Mobile}></img>
            <div className="list" data-aos="fade-up">
              {/* <img src={BlueCircle}></img> */}
              <div>
                <h4 style={{ color: "#0C224F" }}>{t("performance-1-title")}</h4>
                <p>{t("performance-1-text")}</p>
              </div>
            </div>
            <div className="list" data-aos="fade-up">
              {/* <img src={GreenCircle}></img> */}
              <div>
                <h4 style={{ color: "#A6C636" }}>{t("performance-2-title")}</h4>
                <p>{t("performance-2-text")}</p>
              </div>
            </div>
            <div className="list" data-aos="fade-up">
              {/* <img src={YellowCircle}></img> */}
              <div>
                <h4 style={{ color: "#FFC90E" }}>{t("performance-3-title")}</h4>
                <p>{t("performance-3-text")}</p>
              </div>
            </div>
            <div className="list" data-aos="fade-up">
              {/* <img src={PinkCircle}></img> */}
              <div>
                <h4 style={{ color: "#F15A70" }}>{t("performance-4-title")}</h4>
                <p>{t("performance-4-text")}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="imgBox" data-aos="zoom-in">
            {language == "vi" && <img src={Welcome3Vi}></img>}
            {language == "en" && <img src={Welcome3En}></img>}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Welcome3;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0 24px;
  margin-bottom: 100px;
  @media screen and (max-width: 660px) {
    margin-bottom: 0px;
  }

  & > .wrapper {
    width: 100%;
    max-width: 954px;
    margin: auto;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
    }
    @media screen and (max-width: 660px) {
    }
    & > h3 {
      width: 100%;
      margin-top: 150px;
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      margin-bottom: 100px;
      text-align: center;

      @media screen and (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
        white-space: normal;
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      color: #141414;
      font-size: 26px;
      line-height: 43px;
      margin-bottom: 80px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 660px) {
      }
    }
    & > .imgBox {
      margin-bottom: 100px;
      & > img {
        width: 100%;
        margin-bottom: 40px;
        @media screen and (max-width: 1024px) {
          max-width: 300px;
          display: block;
          margin: 0 auto 40px auto;
        }
        @media screen and (max-width: 660px) {
          max-width: 200px;
          display: block;
          margin: 0 auto 40px auto;
        }
      }
      & > .list {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        & > img {
          margin-top: 3px;
          margin-right: 10px;
        }
        & > div > h4 {
          font-size: 18px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 10px;
        }
        & > div > p {
          width: 100%;
          max-width: 375px;
          color: #141414;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          @media screen and (max-width: 660px) {
            max-width: 327px;
          }
        }
      }
    }
  }
`;
