import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import TtestService from "assets/images/ttest_service.png";
import { Trans, useTranslation } from "react-i18next";

const Ttest3 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          <span style={{ color: "#0C224F" }}>T-Test</span>&nbsp;{t("service")}
        </h3>
        <img src={TtestService}></img>
        <TextBg>
          <div className="list" data-aos="fade-up">
            <h4>{t("service-title1")}</h4>
            <div className="flexBox">
              <span>·</span>
              <p>
                <Trans
                  i18nKey="service-text1-1"
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#141414",
                          fontSize: "18px",
                        }}
                      />
                    ),
                  }}
                />
              </p>
            </div>

            <div className="flexBox">
              <span>·</span>
              <p>
                <Trans
                  i18nKey="service-text1-2"
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#141414",
                          fontSize: "18px",
                        }}
                      />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <h4>{t("service-title2")}</h4>
            <div className="flexBox">
              <span>·</span>
              <p>
                <Trans
                  i18nKey="service-text2-1"
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#141414",
                          fontSize: "18px",
                        }}
                      />
                    ),
                  }}
                />
              </p>
            </div>
            <div className="flexBox">
              <span>·</span>
              <p>
                <Trans
                  i18nKey="service-text2-2"
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#141414",
                          fontSize: "18px",
                        }}
                      />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <h4>{t("service-title3")}</h4>
            <div className="flexBox">
              <span>·</span>
              <p>
                <Trans
                  i18nKey="service-text3-1"
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#141414",
                          fontSize: "18px",
                        }}
                      />
                    ),
                  }}
                />
              </p>
            </div>
            <div className="flexBox">
              <span>·</span>
              <p>
                <Trans
                  i18nKey="service-text3-2"
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#141414",
                          fontSize: "18px",
                        }}
                      />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <h4>{t("service-title4")}</h4>
            <div className="flexBox">
              <span>·</span>
              <p>
                <Trans
                  i18nKey="service-text4-1"
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#141414",
                          fontSize: "18px",
                        }}
                      />
                    ),
                  }}
                />
              </p>
            </div>
            <div className="flexBox">
              <span>·</span>
              <p>
                <Trans
                  i18nKey="service-text4-2"
                  components={{
                    strong: (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#141414",
                          fontSize: "18px",
                        }}
                      />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </TextBg>
      </div>
    </Section>
  );
};

export default Ttest3;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0px 24px;
  & > .wrapper {
    width: 100%;
    max-width: 730px;
    margin: auto;
    & > h3 {
      color: #0c224f;
      font-size: 54px;
      font-weight: 700;
      letter-spacing: -0.162px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 100px;
      @media screen and (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 40px;
        font-size: 30px;
      }
      @media screen and (max-width: 660px) {
        font-size: 20px;
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
    & > p {
      color: #141414;
      font-size: 26px;
      line-height: normal;
      margin-bottom: 80px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 660px) {
      }
    }
    & > img {
      width: 100%;
    }
  }
`;

const TextBg = styled.div`
  margin-top: 20px;
  .list {
    padding: 20px 30px;
    border-bottom: 1px solid #0c224f;
    & > h4 {
      color: #0c224f;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 10px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-width: 660px) {
      }
    }
    & > .flexBox {
      display: flex;
      align-items: flex-start;
      & > span {
        margin-right: 5px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
      & > p {
        color: #666;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        margin-bottom: 10px;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 660px) {
        }
      }
    }
  }
`;
