import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import MainVideo from "assets/videos/main-video.mp4";
import MainLanguage from "assets/images/main_language.png";
import MainTtest from "assets/images/main_ttest.png";
import MainPreparatory from "assets/images/main_preparatory.png";
import MainSmartdigital from "assets/images/main_smartdigital.png";
import MainAisolution from "assets/images/main_aisolution.png";
import styled from "styled-components";
import MoreSquareButton from "components/button/MoreSquareButton";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useResponsive from "hooks/useResponsive";

const VideoMain = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    const scrollToElement = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    scrollToElement(); // 초기 로딩 시 스크롤 처리

    window.addEventListener("hashchange", scrollToElement);

    return () => {
      window.removeEventListener("hashchange", scrollToElement);
    };
  }, [location]);

  //페이지 이동
  const movePage = (page) => {
    navigate(`/${page}`);
  };

  return (
    <>
      <Section>
        <ReactPlayer
          width="100%"
          height="100%"
          url={MainVideo}
          muted={true}
          playing={true}
          loop={true}
          playsinline={true}
        />
        <div className="absoluteBox">
          <div className="title">{t("video-main-title")}</div>
        </div>
      </Section>
      <Section2>
        <div className="flexBox">
          <div className="left">
            <div className="mission">MISSION</div>
            <h1 className="kgo">KGO</h1>
          </div>
          <div className="right">
            <div className="light">{t("main-mission-1")}</div>
            <div className="bold">{t("main-mission-2")}</div>
          </div>
        </div>
      </Section2>
      <Section3 id="ourservices">
        <Card>
          <CardImage data-aos="fade-up" $bg={MainLanguage}></CardImage>
          <div data-aos="fade-up" className="textBox">
            <h3 data-aos="fade-up">{t("main-language-title")}</h3>
            <p data-aos="fade-up">{t("main-language-text")}</p>
            <div
              data-aos="fade-up"
              className="btn"
              onClick={() => movePage("language-programs")}
            >
              <MoreSquareButton></MoreSquareButton>
            </div>
          </div>
        </Card>
        <Card>
          <CardImage data-aos="fade-up" $bg={MainTtest}></CardImage>
          <div data-aos="fade-up" className="textBox">
            <h3 data-aos="fade-up">{t("main-ttest-title")}</h3>
            <p data-aos="fade-up">{t("main-ttest-text")}</p>
            <div
              data-aos="fade-up"
              className="btn"
              onClick={() => movePage("t-test")}
            >
              <MoreSquareButton></MoreSquareButton>
            </div>
          </div>
        </Card>
        <Card>
          <CardImage data-aos="fade-up" $bg={MainPreparatory}></CardImage>
          <div data-aos="fade-up" className="textBox">
            <h3 data-aos="fade-up">{t("main-preparatory-title")}</h3>
            <p data-aos="fade-up">{t("main-preparatory-text")}</p>
            <div
              data-aos="fade-up"
              className="btn"
              onClick={() => movePage("preparatory-programs")}
            >
              <MoreSquareButton></MoreSquareButton>
            </div>
          </div>
        </Card>
        <Card>
          <CardImage data-aos="fade-up" $bg={MainSmartdigital}></CardImage>
          <div data-aos="fade-up" className="textBox">
            <h3 data-aos="fade-up">{t("main-smartdigital-title")}</h3>
            <p data-aos="fade-up">{t("main-smartdigital-text")}</p>
            <div
              data-aos="fade-up"
              className="btn"
              onClick={() => movePage("smart-digital-classroom")}
            >
              <MoreSquareButton></MoreSquareButton>
            </div>
          </div>
        </Card>
        <Card>
          <CardImage data-aos="fade-up" $bg={MainAisolution}></CardImage>
          <div data-aos="fade-up" className="textBox">
            <h3 data-aos="fade-up">{t("main-aisolution-title")}</h3>
            <p data-aos="fade-up">{t("main-aisolution-text")}</p>
            <div
              data-aos="fade-up"
              className="btn"
              onClick={() => movePage("ai-solution")}
            >
              <MoreSquareButton></MoreSquareButton>
            </div>
          </div>
        </Card>
      </Section3>
    </>
  );
};

export default VideoMain;

const Section = styled.section`
  position: relative;
  overflow: hidden;
  & > .absoluteBox {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 25px;
    margin: 0 auto;
    & > .title {
      display: block;
      color: #fff;
      font-size: 54px;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: center;
      white-space: pre-line;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        font-size: 26px;
      }
      @media screen and (max-width: 667px) {
        font-size: 20px;
      }
    }
  }
`;

const Section2 = styled.section`
  & > .flexBox {
    display: flex;
    justify-content: center;
    padding-top: 180px;
    padding-bottom: 160px;
    white-space: pre-line;
    @media screen and (max-width: 1024px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @media screen and (max-width: 667px) {
      flex-wrap: wrap;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    & > .left {
      width: 206px;
      margin-right: 81px;
      @media screen and (max-width: 667px) {
        width: 123px;
        margin-right: 0px;
      }
      & > .mission {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #121f3a;
        text-align: center;
        color: #fff;
        font-size: 26px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.078px;
        margin-bottom: 10px;
        @media screen and (max-width: 667px) {
          font-size: 20px;
        }
      }
      & > .kgo {
        line-height: 94px;
        text-align: center;
        color: #0c224f;
        font-size: 74px;
        font-weight: 800;
        @media screen and (max-width: 667px) {
          font-size: 44px;
          line-height: 54px;
          margin-bottom: 20px;
        }
      }
    }
    & > .right {
      @media screen and (max-width: 667px) {
        width: 100%;
      }
      & > .light {
        padding-bottom: 10px;
        color: #141414;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
        @media screen and (max-width: 667px) {
          font-size: 14px;
          text-align: center;
          padding-bottom: 20px;
          max-width: 315px;
          margin: auto;
        }
      }

      & > .bold {
        color: #0c224f;
        font-weight: 800;
        line-height: 44px;
        font-size: 26px;
        text-transform: uppercase;
        @media screen and (max-width: 667px) {
          text-align: center;
          color: #0c224f;
          font-size: 20px;
          line-height: 28px;
          max-width: 315px;
          margin: auto;
        }
      }
    }
  }
`;

const Section3 = styled.section`
  background-color: #121f3a;
  padding-top: 160px;
  padding-bottom: 80px;
  @media screen and (max-width: 667px) {
    padding-top: 20px;
    padding-bottom: 60px;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  //Tablet and Mobile
  @media screen and (max-width: 808px) {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  & > .textBox {
    padding: 70px 40px;
    background-color: white;
    width: 580px;
    position: relative;
    @media screen and (max-width: 1024px) {
      width: 427px;
    }
    @media screen and (max-width: 808px) {
      width: 100%;
      height: 335px;
      padding: 40px 24px;
    }
    & > h3 {
      color: #0c224f;
      font-family: Montserrat;
      font-size: 26px;
      margin-bottom: 20px;
      @media screen and (max-width: 808px) {
        font-size: 20px;
        text-align: center;
      }
    }
    & > p {
      color: #141414;
      font-size: 16px;
      line-height: 22px;
      @media screen and (max-width: 808px) {
        text-align: center;
      }
    }
    & > .btn {
      position: absolute;
      bottom: 40px;
      @media screen and (max-width: 808px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

const CardImage = styled.div`
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 366px;
  height: 400px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    width: 300px;
  }
  @media screen and (max-width: 808px) {
    width: 100%;
  }
`;
