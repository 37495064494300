import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import EnglishLearning from "assets/images/englishlearning.png";
import KoreanLearning from "assets/images/koreanlearning.png";

const Ai1 = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open("http://www.speakingrise.com", "_blank");
  };

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          <div>
            <span style={{ color: "#0C224F" }}>
              {t("innovative-ai-language")}
            </span>
            <br></br>
            {t("learning-solution")}
          </div>
        </h3>
        <p data-aos="fade-up">{t("learning-solution-text")}</p>
        <ButtonLink data-aos="fade-up" onClick={handleClick}>
          Visit our AI solution : Speaking Rise
        </ButtonLink>
        <FlexBox data-aos="fade-up">
          <div className="imgBox">
            <img src={EnglishLearning}></img>
          </div>
          <div className="description">
            <h4>{t("english-learning")}</h4>
            <div className="list">
              <div className="number">1.</div>
              <div className="text">
                <div className="title">{t("engilsh-learning-title1")}</div>
                <div className="sub">{t("engilsh-learning-text1")}</div>
              </div>
            </div>
            <div className="list">
              <div className="number">2.</div>
              <div className="text">
                <div className="title">{t("engilsh-learning-title2")}</div>
                <div className="sub">{t("engilsh-learning-text2")}</div>
              </div>
            </div>
            <div className="list">
              <div className="number">3.</div>
              <div className="text">
                <div className="title">{t("engilsh-learning-title3")}</div>
                <div className="sub">{t("engilsh-learning-text3")}</div>
              </div>
            </div>
          </div>
        </FlexBox>
        <FlexBox data-aos="fade-up">
          <div className="imgBox">
            <img src={KoreanLearning}></img>
          </div>
          <div className="description">
            <h4>{t("korean-learning")}</h4>
            <div className="list">
              <div className="number">1.</div>
              <div className="text">
                <div className="title">{t("korean-learning-title1")}</div>
                <div className="sub">{t("korean-learning-text1")}</div>
              </div>
            </div>
            <div className="list">
              <div className="number">2.</div>
              <div className="text">
                <div className="title">{t("korean-learning-title2")}</div>
                <div className="sub">{t("korean-learning-text2")}</div>
              </div>
            </div>
            <div className="list">
              <div className="number">3.</div>
              <div className="text">
                <div className="title">{t("korean-learning-title3")}</div>
                <div className="sub">{t("korean-learning-text3")}</div>
              </div>
            </div>
          </div>
        </FlexBox>
      </div>
    </Section>
  );
};

export default Ai1;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0px 24px;
  & > .wrapper {
    width: 100%;
    max-width: 730px;
    margin: auto;
    & > h3 {
      white-space: nowrap;
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      margin-top: 80px;
      margin-bottom: 40px;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        font-size: 30px;
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
      }
      & > .line {
        width: 100%;
        height: 1px;
        background-color: #0c224f;
        margin-left: 40px;
        margin-bottom: 15px;
        @media screen and (max-width: 1024px) {
          margin-left: 20px;
        }
        @media screen and (max-width: 660px) {
          margin-left: 10px;
          margin-bottom: 5px;
        }
      }
    }
    & > p {
      color: #666;
      text-align: center;
      font-size: 26px;
      font-weight: 400;
      margin-bottom: 40px;
      @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 357px;
        font-size: 16px;
        line-height: 22px;
        margin: 0 auto 20px auto;
      }
      @media screen and (max-width: 660px) {
      }
    }
  }
`;

const FlexBox = styled.div`
  border-radius: 20px;
  width: 100%;
  max-width: 730px;
  margin: 0 auto 80px auto;
  flex-wrap: wrap;
  @media screen and (max-width: 1024px) {
    max-width: 375px;
    margin: 0 auto 40px auto;
  }

  & > .imgBox {
    & > img {
      width: 100%;
      margin-bottom: 40px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
  }
  & > .description {
    & > h4 {
      color: #141414;
      font-size: 26px;
      font-weight: 700;
      line-height: 30px; /* 115.385% */
      text-transform: uppercase;
      margin-bottom: 40px;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        margin-bottom: 11px;
      }
    }
    & > .list {
      display: flex;
      align-items: flex-start;
      margin-bottom: 40px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }
      & > .number {
        color: var(--blue--0C, #0c224f);
        text-align: center;
        font-family: Montserrat;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right: 6px;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
      }
      & > .text {
        & > .title {
          color: #0c224f;
          font-size: 26px;
          font-weight: 700;
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
        & > .sub {
          color: #666;
          font-weight: 400;
        }
      }
    }
  }
`;

const ButtonLink = styled.button`
  display: block;
  margin: auto;
  padding: 14px 70px;
  border-radius: 50px;
  background: #0c224f;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 40px;
  cursor: pointer;
  @media screen and (max-width: 660px) {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
  }
`;
