import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SmartDigitalClassroom from "assets/images/smartdigitalclassroom.png";
import { useTranslation } from "react-i18next";

const Smart1 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="wrapper">
        <p data-aos="fade-up" className="mb">
          {t("smart-digital-classroom-text1")}
        </p>
        <div className="imgWrapper" data-aos="fade-up">
          <img src={SmartDigitalClassroom}></img>
        </div>
        <p data-aos="fade-up">{t("smart-digital-classroom-text2")}</p>
      </div>
    </Section>
  );
};

export default Smart1;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 80px 24px;
  @media screen and (max-width: 660px) {
    padding: 40px 24px;
  }
  & > .wrapper {
    width: 100%;
    max-width: 730px;
    margin: auto;
    & > p {
      color: #141414;
      text-align: center;
      font-size: 26px;
      font-weight: 400;
      @media screen and (max-width: 1024px) {
        line-height: 22px;
        font-size: 16px;
      }
      @media screen and (max-width: 660px) {
      }
    }
    & > .mb {
      margin-bottom: 60px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
    & > .imgWrapper {
      margin-bottom: 60px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }
      & > img {
        width: 100%;
      }
    }
  }
`;
