import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Benefits1 from "assets/images/benefits1.png";
import Benefits2 from "assets/images/benefits2.png";
import Benefits3 from "assets/images/benefits3.png";
import Benefits4 from "assets/images/benefits4.png";
import Benefits5 from "assets/images/benefits5.png";
import HowToUseEn from "assets/images/howtouse-en.png";
import HowToUseVi from "assets/images/howtouse-vi.png";
import HowToUseMobileEn from "assets/images/howtouse-mobile-en.png";
import HowToUseMobileVi from "assets/images/howtouse-mobile-vi.png";
import { Trans, useTranslation } from "react-i18next";
import { useLangContext } from "context/LangContext";
import useResponsive from "hooks/useResponsive";

const Ttest4 = () => {
  const { t } = useTranslation();

  const { language } = useLangContext();

  const { isMobile } = useResponsive();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          <Trans
            i18nKey="benefits-of-ttest"
            components={{
              strong: (
                <span
                  style={{
                    color: "#0C224F",
                  }}
                />
              ),
            }}
          />
        </h3>
        <GridBox>
          <div className="list" data-aos="fade-up">
            <img src={Benefits1}></img>
            <div className="description">
              <h4>{t("benefits-title1")}</h4>
              <p>{t("benefits-text1")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <img src={Benefits2}></img>
            <div className="description">
              <h4>{t("benefits-title2")}</h4>
              <p>{t("benefits-text2")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <img src={Benefits3}></img>
            <div className="description">
              <h4>{t("benefits-title3")}</h4>
              <p>{t("benefits-text3")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <img src={Benefits4}></img>
            <div className="description">
              <h4>{t("benefits-title4")}</h4>
              <p>{t("benefits-text4")}</p>
            </div>
          </div>
          <div className="list" data-aos="fade-up">
            <img src={Benefits5}></img>
            <div className="description">
              <h4>{t("benefits-title5")}</h4>
              <p>{t("benefits-text5")}</p>
            </div>
          </div>
        </GridBox>
        <h3 data-aos="fade-up">{t("how-to-use")}</h3>
        {isMobile ? (
          <div className="imgWrapper">
            {language == "vi" && (
              <img src={HowToUseMobileVi} data-aos="fade-right"></img>
            )}
            {language == "en" && (
              <img src={HowToUseMobileEn} data-aos="fade-right"></img>
            )}
          </div>
        ) : (
          <div className="imgWrapper">
            {language == "vi" && (
              <img src={HowToUseVi} data-aos="fade-right"></img>
            )}
            {language == "en" && (
              <img src={HowToUseEn} data-aos="fade-right"></img>
            )}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Ttest4;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0px 24px;
  margin-bottom: 220px;
  @media screen and (max-width: 660px) {
    margin-bottom: 100px;
  }
  & > .wrapper {
    width: 100%;
    max-width: 1260px;
    margin: auto;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
    }
    & > h3 {
      color: #141414;
      font-size: 54px;
      font-weight: 700;
      margin-bottom: 40px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 200px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 800;
        font-size: 20px;
        height: auto;
      }

      & > .line {
        width: 100%;
        height: 1px;
        background-color: #0c224f;
        margin-left: 40px;
        margin-bottom: 15px;
        @media screen and (max-width: 1024px) {
          margin-bottom: 5px;
        }
        @media screen and (max-width: 660px) {
        }
      }
    }
    & > p {
      color: #141414;
      font-size: 26px;
      line-height: normal;
      margin-bottom: 80px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 660px) {
      }
    }
    & > .imgWrapper {
      width: 100%;
      max-width: 1280px;
      & > img {
        width: 100%;
        display: block;
        margin: auto;
        @media screen and (max-width: 660px) {
          max-width: 327px;
        }
      }
    }
  }
`;

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 3개의 동일한 너비의 컬럼 생성 */
  gap: 10px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr); /* 3개의 동일한 너비의 컬럼 생성 */
    gap: 20px;
  }
  @media screen and (max-width: 660px) {
    display: flex;
    flex-wrap: wrap;
  }
  & > .list {
    height: 310px;
    border-radius: 20px;
    background: #0c224f;
    overflow: hidden;
    position: relative;
    padding: 30px 20px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0px;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-right: 0px;
    }
    @media screen and (max-width: 660px) {
      height: auto;
    }
    & > img {
      display: block;
      margin: 0 auto 20px auto;
      @media screen and (max-width: 1024px) {
        width: 50px;
      }
      @media screen and (max-width: 660px) {
      }
    }
    & > .description {
      color: white;
      & > h4 {
        color: #fff;
        font-size: 26px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 10px;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 660px) {
        }
      }
      & > p {
        font-size: 18px;
        text-align: center;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 660px) {
        }
      }
    }
  }
`;
