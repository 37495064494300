import React from "react";
import MoreBtnImg from "assets/images/more_square_btn.png";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

const MoreSquareButton = () => {
  const { t } = useTranslation();

  return (
    <Button>
      <span>{t("more")}</span> <img src={MoreBtnImg}></img>
    </Button>
  );
};

export default MoreSquareButton;

const moveSideways = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > span {
    color: #0C224F;
    font-size: 16px;
    font-weight: 400;
    margin-right: 10px;
  }
  &:hover > img {
    animation: ${moveSideways} 0.8s infinite;
  }
`;
