import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import WelcomeImg4 from "assets/images/welcome4.png";
import { useTranslation } from "react-i18next";

const Welcome4 = () => {
  const { t } = useTranslation();

  return (
    <Section $bg={WelcomeImg4}>
      <div className="wrapper">
        <h3 data-aos="fade-up">{t("our-unique-focus")}</h3>
        <p data-aos="fade-up">
          <span style={{ color: "#fff", fontWeight: "700" }}>
            {t("kgo-description")}
          </span>
          {t("our-unique-focus-text1")}
        </p>
        <p data-aos="fade-up">{t("our-unique-focus-text2")}</p>
        <p data-aos="fade-up"> {t("our-unique-focus-text3")}</p>
      </div>
    </Section>
  );
};

export default Welcome4;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0px 24px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(12, 34, 79, 0.5);
    z-index: 1;
  }

  & > .wrapper {
    width: 100%;
    max-width: 954px;
    margin: auto;
    z-index: 2; /* wrapper 요소의 z-index 설정 */
    position: relative; /* z-index가 적용되도록 position 설정 */
    margin-bottom: 240px;
    @media screen and (max-width: 1024px) {
      max-width: 376px;
      margin-bottom: 80px;
    }
    @media screen and (max-width: 660px) {
      margin-bottom: 40px;
    }
    & > h3 {
      white-space: nowrap;
      color: white;
      font-size: 54px;
      font-weight: 800;
      padding-top: 200px;
      margin-bottom: 80px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        padding-top: 80px;
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
        white-space: normal;
      }
      @media screen and (max-width: 660px) {
        padding-top: 40px;
        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      color: #fff;
      font-size: 26px;
      line-height: 43px;
      margin-bottom: 40px;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 660px) {
      }
    }
  }
`;
