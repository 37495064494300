import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import StudyAbroad from "assets/images/styudyabroad.png";
import JobPreparation from "assets/images/jobpreparation.png";
import { useTranslation } from "react-i18next";

const Preparatory1 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          {t("preparatory")}&nbsp;
          <span style={{ color: "#0C224F" }}>{t("programs")}</span>
        </h3>
        <FlexBox>
          <img data-aos="fade-up" src={StudyAbroad}></img>
          <div className="description">
            <h4 data-aos="fade-up">{t("study-aboard")}</h4>
            <p data-aos="fade-up">{t("study-aboard-text")}</p>
          </div>
        </FlexBox>
        <FlexBox>
          <img src={JobPreparation} data-aos="fade-up"></img>
          <div className="description">
            <h4 data-aos="fade-up">{t("job-preparation")}</h4>
            <p data-aos="fade-up">{t("job-preparation-text")}</p>
          </div>
        </FlexBox>
      </div>
    </Section>
  );
};

export default Preparatory1;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0 24px;
  margin-bottom: 100px;

  & > .wrapper {
    width: 100%;
    max-width: 772px;
    margin: auto;
    @media screen and (max-width: 1024px) {
      max-width: 429px;
    }
    & > h3 {
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      margin-top: 80px;
      margin-bottom: 40px;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 40px;
        font-size: 30px;
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 40px auto;
  @media screen and (max-width: 1024px) {
  }
  &.reverse {
    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
    }
  }
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 660px) {
  }
  & > .description {
    @media screen and (max-width: 1024px) {
      margin: 0;
    }
    & > h4 {
      color: #141414;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 26px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 660px) {
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 5px;
      }
    }
    & > p {
      color: #666;
      font-size: 26px;
      font-weight: 400;
      line-height: 36px; /* 165.385% */
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 26px;
      }
      @media screen and (max-width: 660px) {
        line-height: 22px;
      }
    }
  }
  & > img {
    width: 100%;
    margin-bottom: 20px;
  }
`;
