import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "assets/images/kgo_logo.png";
import DownArrow from "assets/images/down_arrow.png";
import { useMediaQuery } from "react-responsive";
import ScrollToTopButton from "components/button/ScrollToTopButton";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 810 });
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const toggleOpenModal = () => {
    setOpenModal((prev) => !prev);
  };

  const openLinkInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <FooterWrapper>
        <FooterHeader>
          <FooterLogo>
            <img
              width={126}
              onClick={() => navigate("/")}
              src={Logo}
              alt="logo"
            ></img>
          </FooterLogo>
          <FamilySite onClick={toggleOpenModal}>
            <div>Family Site</div>
            <span>
              <img src={DownArrow} alt="arrow"></img>
            </span>
            {openModal && (
              <div className="familySiteModal">
                <ul>
                  <li onClick={() => openLinkInNewTab("http://www.ttcedu.com")}>
                    TTC EDU
                  </li>
                  <li
                    onClick={() =>
                      openLinkInNewTab("http://www.ttceducation.net")
                    }
                  >
                    CAMPUS EDU
                  </li>
                  <li onClick={() => openLinkInNewTab("http://ttest.kr")}>
                    T-TEST
                  </li>
                  <li
                    onClick={() => openLinkInNewTab("http://www.koreago.co.kr")}
                  >
                    KGO KOREA
                  </li>
                  <li
                    onClick={() => openLinkInNewTab("http://www.grib-iot.com")}
                  >
                    GRIB
                  </li>
                  <li onClick={() => openLinkInNewTab("http://www.e-test.vn")}>
                    E-TEST
                  </li>
                </ul>
              </div>
            )}
          </FamilySite>
        </FooterHeader>
        <FooterContent>
          <div className="infoBox">
            <div className="info">
              <p>
                KGO ｜ CEO : Ye ji Kang ｜President of Vietnam Corporation: Roy
                Hong
              </p>
              <p>Contact : support@kgo-global.com</p>
            </div>
            <div className="info">
              <p>{t("seoul-branch")}</p>
              <p>{t("hanoi-branch")}</p>
            </div>
          </div>
        </FooterContent>
      </FooterWrapper>
      <ScrollToTopButton></ScrollToTopButton>
    </>
  );
};

export default Footer;

const FooterWrapper = styled.footer`
  width: 100%;
  min-width: 320px;
  background-color: #212327;
  color: #fff;
  padding: 70px 70px 80px 70px;
  @media screen and (max-width: 1024px) {
    padding: 40px 40px 80px 40px;
  }
  @media screen and (max-width: 667px) {
    padding: 40px 24px 40px 24px;
  }
`;

const FooterHeader = styled.div`
  border-bottom: 1px solid #fff;
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const FooterLogo = styled.div``;

const FamilySite = styled.div`
  border-radius: 4px;
  border: 1px solid #fff;
  width: 179px;
  height: 43px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  & > .familySiteModal {
    background-color: #212327;
    border-radius: 4px;
    border: 1px solid #fff;
    position: absolute;
    width: 100%;
    top: calc(100% + 10px);
    left: 0;
    padding: 7px 0;
    & > ul > li {
      padding: 5px 12px;
      cursor: pointer;
      & > a {
        color: white;
      }
      &:hover {
        background-color: #68707d;
      }
    }
  }
`;

const FooterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  @media screen and (max-width: 667px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1024px) {
    display: block;
  }
  & > .infoBox {
    display: flex;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    & > .info {
      margin-right: 110px;
      &:last-child {
        margin-right: 0;
      }
      @media screen and (max-width: 667px) {
        margin-right: 0px;
      }
      & > p {
        margin-bottom: 10px;
      }
    }
  }
`;
