import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import KeyAdvantagesEn from "assets/images/keyadvantages-en.png";
import KeyAdvantagesVi from "assets/images/keyadvantages-vi.png";
import KeyAdvantagesMobileEn from "assets/images/keyadvantages-mobile-en.png";
import KeyAdvantagesMobileVi from "assets/images/keyadvantages-mobile-vi.png";
import { useTranslation } from "react-i18next";
import { useLangContext } from "context/LangContext";
import useResponsive from "hooks/useResponsive";

const Ai2 = () => {
  const { t } = useTranslation();

  const { language } = useLangContext();

  const { isMobile } = useResponsive();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          {t("key-advantages")}&nbsp;
          <span style={{ color: "#0C224F" }}>{t("advantages")}</span>
        </h3>
        {isMobile ? (
          <div className="imgWrapper" data-aos="fade-right">
            {language == "vi" && <img src={KeyAdvantagesMobileVi}></img>}
            {language == "en" && <img src={KeyAdvantagesMobileEn}></img>}
          </div>
        ) : (
          <div className="imgWrapper" data-aos="fade-right">
            {language == "vi" && <img src={KeyAdvantagesVi}></img>}
            {language == "en" && <img src={KeyAdvantagesEn}></img>}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Ai2;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0 24px;

  & > .wrapper {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto 100px auto;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
    }
    & > h3 {
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      margin-top: 160px;
      margin-bottom: 100px;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        margin-top: 60px;
        margin-bottom: 40px;
        font-size: 30px;
      }
      @media screen and (max-width: 660px) {
        font-size: 20px;
      }
    }

    & > .imgWrapper {
      & > img {
        width: 100%;
        @media screen and (max-width: 660px) {
          max-width: 263px;
          margin: auto;
          display: block;
        }
      }
    }
  }
`;
