import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import TtestImg from "assets/images/ttest_img.png";
import { useTranslation } from "react-i18next";

const Ttest1 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">{t("testing-center")}</h3>
        <div className="line"></div>
        <h4 data-aos="fade-up">{t("welcome-t-test")}</h4>
        <p data-aos="fade-up">{t("welcome-t-test-text")}</p>
        <div className="flexBox">
          <img src={TtestImg} data-aos="fade-up"></img>
          <div className="pBox">
            <p data-aos="fade-up">{t("welcome-t-test-img1")}</p>
            <p data-aos="fade-up">{t("welcome-t-test-img2")}</p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Ttest1;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0px 24px;

  & > .wrapper {
    width: 100%;
    max-width: 954px;
    margin: 0 auto 80px auto;
    @media screen and (max-width: 1024px) {
      max-width: 717px;
      margin: 0 auto 60px auto;
    }
    @media screen and (max-width: 660px) {
      margin: 0 auto 40px auto;
    }
    & > h3 {
      color: #0c224f;
      text-align: center;
      font-size: 54px;
      font-weight: 900;
      line-height: 94px; /* 138.235% */
      letter-spacing: -0.204px;
      text-transform: uppercase;
      margin-top: 80px;
      white-space: pre-line;
      @media screen and (max-width: 1024px) {
        font-size: 30px;
        line-height: normal; /* 138.235% */
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        font-size: 20px;
      }
    }
    & > .line {
      width: 1px;
      height: 70px;
      background-color: #000;
      margin: 40px auto;
      @media screen and (max-width: 660px) {
        margin: 20px auto;
      }
    }
    & > h4 {
      color: #0c224f;
      font-size: 26px;
      font-weight: 700;
      line-height: 24px; /* 138.462% */
      letter-spacing: -0.078px;
      text-align: center;
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 26px;
      }
      @media screen and (max-width: 660px) {
        font-size: 20px;
      }
    }
    & > p {
      color: #666;
      text-align: center;
      font-size: 26px;
      font-weight: 400;
      line-height: 36px; /* 138.462% */
      letter-spacing: -0.078px;
      text-align: center;
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: normal;
      }
      @media screen and (max-width: 660px) {
        line-height: 22px; /* 137.5% */
      }
    }
    & > .flexBox {
      & > img {
        display: block;
        margin: 0 auto 20px auto;
        width: 100%;
      }
      & > .pBox {
        width: 100%;
        color: #0c224f;
        font-size: 26px;
        text-align: center;
        font-weight: 700;
        line-height: 36px; /* 138.462% */
        @media screen and (max-width: 1024px) {
          font-size: 16px;
          line-height: normal;
        }
        @media screen and (max-width: 660px) {
          line-height: 22px; /* 137.5% */
        }
      }
    }
  }
`;
