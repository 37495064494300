import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Curriculum from "assets/images/curriculum.png";
import Korean1 from "assets/images/korean1.png";
import Korean2 from "assets/images/korean2.png";
import Korean3 from "assets/images/korean3.png";
import Korean4 from "assets/images/korean4.png";
import Korean5 from "assets/images/korean5.png";
import Korean6 from "assets/images/korean6.png";
import { Trans, useTranslation } from "react-i18next";

const Language2 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          <Trans
            i18nKey="korean-program"
            components={{
              strong: (
                <span
                  style={{
                    color: "#0C224F",
                  }}
                />
              ),
            }}
          />
        </h3>
        <p data-aos="fade-up">{t("korean-text")}</p>
        <GridBox>
          <div className="list">
            <img src={Korean1}></img>
            <div className="title">{t("korean-img1")}</div>
            <div className="cover">
              <p>{t("korean-img-text1")}</p>
            </div>
          </div>
          <div className="list">
            <img src={Korean2}></img>
            <div className="title">{t("korean-img2")}</div>
            <div className="cover">
              <p>{t("korean-img-text2")}</p>
            </div>
          </div>
          <div className="list">
            <img src={Korean3}></img>
            <div className="title">{t("korean-img3")}</div>
            <div className="cover">
              <p>{t("korean-img-text3")}</p>
            </div>
          </div>
          <div className="list">
            <img src={Korean4}></img>
            <div className="title">{t("korean-img4")}</div>
            <div className="cover">
              <p>{t("korean-img-text4")}</p>
            </div>
          </div>
          <div className="list">
            <img src={Korean5}></img>
            <div className="title">{t("korean-img5")}</div>
            <div className="cover">
              <p>{t("korean-img-text5")}</p>
            </div>
          </div>
          <div className="list">
            <img src={Korean6}></img>
            <div className="title">{t("korean-img6")}</div>
            <div className="cover">
              <p>{t("korean-img-text6")}</p>
            </div>
          </div>
        </GridBox>
      </div>
    </Section>
  );
};

export default Language2;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0 24px;
  & > .wrapper {
    width: 100%;
    max-width: 1182px;
    margin: auto;
    padding-bottom: 100px;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
    }

    & > h3 {
      color: #141414;
      text-align: center;
      font-family: Montserrat;
      font-size: 54px;
      font-weight: 700;
      letter-spacing: -0.162px;
      text-transform: uppercase;
      margin-top: 80px;
      white-space: pre-line;
      margin-bottom: 60px;
      @media screen and (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 20px;
        font-size: 30px;
        height: auto;
      }
      @media screen and (max-width: 660px) {
        margin-top: 80px;
        margin-bottom: 20px;
        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      color: #141414;
      text-align: center;
      font-family: Montserrat;
      font-size: 26px;
      font-weight: 400;
      letter-spacing: -0.078px;
      margin-bottom: 60px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 660px) {
      }
    }
  }
`;

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개의 동일한 너비의 컬럼 생성 */
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 3개의 동일한 너비의 컬럼 생성 */
  }
  @media screen and (max-width: 660px) {
    grid-template-columns: repeat(1, 1fr); /* 3개의 동일한 너비의 컬럼 생성 */
  }
  gap: 20px; /* 셀 간격 */
  & > .list {
    border-radius: 20px;
    border: 1px solid #e9e9e9;
    background: #fff;
    min-height: 687px;
    @media screen and (max-width: 660px) {
      min-height: 365px;
    }
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    padding-bottom: 20px;
    & > img {
      width: 100%;
      object-fit: cover;
      margin-bottom: 20px;
    }
    & > .title {
      color: #141414;
      text-align: center;
      font-size: 26px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
      padding: 0 20px;
      white-space: pre-line;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-width: 660px) {
      }
    }
    & > .cover {
      & > p {
        color: #141414;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        padding: 0 20px;
      }
    }
  }
`;
