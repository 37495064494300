import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Vision1 from "assets/images/vision1.png";
import Vision2 from "assets/images/vision2.png";
import Vision3 from "assets/images/vision3.png";
import { useTranslation } from "react-i18next";
import useResponsive from "hooks/useResponsive";
import WhiteCircle from "assets/images/white-sm-circle.png";

const Welcome6 = () => {
  const { t } = useTranslation();

  const { isTabletAndMobile } = useResponsive();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">{t("vision")}</h3>
        <p data-aos="fade-up">{t("vision-text")}</p>
        <div className="imgWrapper">
          {isTabletAndMobile ? (
            <>
              <FlexBox>
                <div className="box" data-aos="fade-up">
                  <img src={Vision1} />
                </div>
                <div className="box" data-aos="fade-up">
                  <img src={Vision2} />
                </div>
                <div className="box" data-aos="fade-up">
                  <img src={Vision3} />
                </div>
              </FlexBox>

              <div className="circleTextBox">
                <div className="circleText" data-aos="fade-up">
                  <img src={WhiteCircle}></img>
                  <p>{t("vision-img1")}</p>
                </div>
                <div className="circleText" data-aos="fade-up">
                  <img src={WhiteCircle}></img>
                  <p>{t("vision-img2")}</p>
                </div>
                <div className="circleText" data-aos="fade-up">
                  <img src={WhiteCircle}></img>
                  <p>{t("vision-img3")}</p>
                </div>
              </div>
            </>
          ) : (
            <FlexBox>
              <div className="box">
                <img src={Vision1} data-aos="fade-up" />
                <p data-aos="fade-up">{t("vision-img1")}</p>
              </div>
              <div className="box">
                <img src={Vision2} data-aos="fade-up" />
                <p data-aos="fade-up">{t("vision-img2")}</p>
              </div>
              <div className="box">
                <img src={Vision3} data-aos="fade-up" />
                <p data-aos="fade-up">{t("vision-img3")}</p>
              </div>
            </FlexBox>
          )}
        </div>
      </div>
    </Section>
  );
};

export default Welcome6;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  background-color: #0c224f;
  padding: 0px 24px;

  & > .wrapper {
    width: 100%;
    max-width: 1055px;
    margin: auto;
    z-index: 2; /* wrapper 요소의 z-index 설정 */
    position: relative; /* z-index가 적용되도록 position 설정 */
    margin-bottom: 200px;
    @media screen and (max-width: 1024px) {
      max-width: 717px;
      margin-bottom: 124px;
    }
    @media screen and (max-width: 660px) {
      margin-bottom: 20px;
    }
    & > h3 {
      white-space: nowrap;
      color: white;
      font-size: 54px;
      font-weight: 800;
      margin-top: 200px;
      margin-bottom: 80px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
        white-space: normal;
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      width: 100%;
      max-width: 954px;
      color: #fff;
      text-align: center;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 auto 40px auto;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px; /* 137.5% */
      }
      @media screen and (max-width: 660px) {
        margin: 0 auto 20px auto;
      }
    }
    & > .imgWrapper {
      width: 100%;
      max-width: 1177px;
      margin: auto;
      h3 {
        color: white;
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 15px;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 660px) {
        }
      }
      & > .circleTextBox {
        color: white;
        font-size: 16px;
        & > .circleText {
          display: flex;
          align-items: flex-start;
          & > img {
            margin-top: 5px;
            margin-right: 8px;
          }
          & > p {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`;

const FlexBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px; /* 셀 간격 */
  margin-bottom: 40px;
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 660px) {
    gap: 18px; /* 셀 간격 */
    margin-bottom: 20px;
  }
  & > .box {
    width: 100%;
    max-width: 336px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 12px;
    border: 0.947px solid #fff;
    background: linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.4) 19.2%,
      rgba(255, 255, 255, 0.12) 83.8%
    );
    box-shadow: 0px 3.789px 32.206px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(28.417034149169922px);
    @media screen and (max-width: 660px) {
      padding: 11px;
    }
    & > img {
      width: 100%;
      max-width: 170px;
    }
    & > p {
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      margin-top: 40px;
    }
  }
`;
