import React, { createContext, useContext, useEffect, useState } from "react";
import i18n from "lang/i18n";
import { useLocation, useParams } from "react-router-dom";

const LangContext = createContext();

export function LangContextProvider({ children }) {
  const [language, setLanguage] = useState();

  useEffect(() => {
    // 로컬 스토리지에서 언어 설정 가져오기
    const storedLang = localStorage.getItem("lang");

    // 가져온 언어 설정이 있으면 해당 언어로 설정하고, 없으면 기본값 'en'으로 설정
    const initialLang = storedLang || "en";

    // 언어 설정하기
    setLanguage(initialLang);
    i18n.changeLanguage(initialLang);
  }, []);

  const changeLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <LangContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LangContext.Provider>
  );
}

export function useLangContext() {
  return useContext(LangContext);
}
