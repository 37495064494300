import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useScrollRestoration = () => {
  const location = useLocation();
  const positions = useRef({});

  useEffect(() => {
    const handlePopState = () => {
      if (positions.current[location.key]) {
        window.scrollTo(
          positions.current[location.key].x,
          positions.current[location.key].y
        );
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location]);

  useEffect(() => {
    if (positions.current[location.key]) {
      window.scrollTo(
        positions.current[location.key].x,
        positions.current[location.key].y
      );
    }

    return () => {
      positions.current[location.key] = {
        x: window.scrollX,
        y: window.scrollY,
      };
    };
  }, [location]);

  return null;
};

export default useScrollRestoration;
