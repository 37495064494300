import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MenuXButton from "assets/images/x_white_btn.png";
import { useTranslation } from "react-i18next";
import { useLangContext } from "context/LangContext";

const MenuModal = ({ open, close }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { language, changeLanguage } = useLangContext();

  const clickHandler = (lang) => {
    changeLanguage(lang);
  };

  const movePage = (page) => {
    const targetPath = `/${page}`;

    if (location.pathname === targetPath) {
      // 같은 페이지일 경우
      window.location.href = targetPath;
    } else {
      // 다른 페이지일 경우
      navigate(targetPath);
      close();
    }
  };

  return (
    <ModalWrapper open={open} onMouseLeave={() => close()}>
      <div className="closeBtn" onClick={() => close()}>
        <img width={44} alt="btn" src={MenuXButton}></img>
      </div>
      {/* 개인정보 설정 버튼(프로필) */}
      <LanguageSelect>
        <div
          onClick={() => clickHandler("en")}
          style={{ fontWeight: language == "en" ? "700" : "300" }}
        >
          ENG
        </div>
        <div>|</div>
        <div
          onClick={() => clickHandler("vi")}
          style={{ fontWeight: language == "vi" ? "700" : "300" }}
        >
          VND
        </div>
      </LanguageSelect>
      <Menu>
        <div className="yesSubMenu" onClick={() => movePage("about-kgo")}>
          <span>{t("welcome-submenu1")}</span>
        </div>
      </Menu>
      <Menu>
        <div className="noSubMenu" onClick={() => movePage("about-kgo")}>
          <span>{t("welcome-submenu1")}</span>
        </div>
      </Menu>

      <Menu>
        <div className="yesSubMenu" onClick={() => movePage("#ourservices")}>
          <span>{t("menu-ourservice")}</span>
        </div>
      </Menu>
      <Menu>
        <div
          className="noSubMenu"
          onClick={() => movePage("language-programs")}
        >
          <span>{t("language-title")}</span>
        </div>
      </Menu>
      <Menu>
        <div className="noSubMenu" onClick={() => movePage("t-test")}>
          <span>{t("ttest-title")}</span>
        </div>
      </Menu>
      <Menu>
        <div
          className="noSubMenu"
          onClick={() => movePage("preparatory-programs")}
        >
          <span>{t("preparatory-title")}</span>
        </div>
      </Menu>
      <Menu>
        <div
          className="noSubMenu"
          onClick={() => movePage("smart-digital-classroom")}
        >
          <span>{t("smartdigital-title")}</span>
        </div>
      </Menu>
      <Menu>
        <div className="noSubMenu" onClick={() => movePage("ai-solution")}>
          <span>{t("aisolution-title")}</span>
        </div>
      </Menu>
    </ModalWrapper>
  );
};

export default MenuModal;

const ModalWrapper = styled.div`
  width: ${(props) => (props.open ? "100%" : "0px")};
  /* width: 100%; */
  max-width: 461px;
  position: fixed;
  transition: all.2s;
  z-index: 200;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 6px;
  background-color: #212327;
  overflow: hidden;
  box-shadow: -8px 8px 4px 0px rgba(14, 20, 39, 0.25);
  padding-top: 72px;
  @media screen and (max-width: 1024px) {
    max-width: 341px;
  }
  .closeBtn {
    position: absolute;
    top: 30px;
    right: 60px;
    cursor: pointer;
    @media screen and (max-width: 667px) {
      right: 30px;
    }
  }
`;

const LanguageSelect = styled.div`
  display: flex;
  margin-left: 70px;
  margin-bottom: 40px;
  & > div {
    font-size: 18px;
    color: white;
    margin-right: 10px;
    cursor: pointer;
  }
  @media screen and (max-width: 667px) {
    margin-left: 40px;
  }
`;

const Menu = styled.div`
  color: white;
  cursor: pointer;
  & > .yesSubMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 60px;
    font-size: 24px;
    font-weight: 700;
    height: 55px;
    text-transform: uppercase;
    &:hover {
      background: #272c37;
    }
    @media screen and (max-width: 1024px) {
      letter-spacing: -0.06px;
      font-size: 20px;
      padding-left: 40px;
    }
  }
  & > .noSubMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 10px;
    color: #7a7a7a;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    height: 55px;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
    &:hover {
      background: #272c37;
    }
    @media screen and (max-width: 1024px) {
      padding-left: 60px;
      padding-right: 40px;
    }
  }
`;
