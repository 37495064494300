import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import ShakeHand from "assets/images/shakehand.png";
import { useTranslation } from "react-i18next";

const Welcome8 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <BackgroundBox $bg={ShakeHand}>
        <h3 data-aos="fade-up" style={{ marginBottom: "20px" }}>
          {t("welcome8-text")}
        </h3>
      </BackgroundBox>
    </Section>
  );
};

export default Welcome8;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  & > .colorText {
    color: #0c224f;
    text-align: center;
    font-size: 54px;
    font-weight: 700;
    width: 100%;
    margin: auto;
    max-width: 1370px;
    padding: 0 24px;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      font-size: 20px;
    }
    @media screen and (max-width: 660px) {
    }
  }
`;

const BackgroundBox = styled.div`
  padding: 0 24px;
  width: 100%;
  height: 615px;
  margin-bottom: 150px;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    height: 340px;
  }
  @media screen and (max-width: 660px) {
    margin-bottom: 100px;
    height: 392px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  & > h3 {
    width: 100%;
    max-width: 554px;
    text-align: center;
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    line-height: 43px; /* 165.385% */
    letter-spacing: -0.078px;
    z-index: 2; /* wrapper 요소의 z-index 설정 */
    position: relative; /* z-index가 적용되도록 position 설정 */
    white-space: pre-line;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
      max-width: 536px;
      font-size: 20px;
      line-height: 30px;
    }
    @media screen and (max-width: 660px) {
      max-width: 327px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;
