import { useMediaQuery } from "react-responsive";

const useResponsive = () => {
  const isTabletAndMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 660 });
  const isSmartMobile = useMediaQuery({ maxWidth: 725 });
  const isTablet = useMediaQuery({ minWidth: 660, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return {
    isTabletAndMobile,
    isMobile,
    isTablet,
    isDesktop,
    isSmartMobile,
  };
};

export default useResponsive;
