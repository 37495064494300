import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TopBtn from "assets/images/top_btn.png";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <TopButton onClick={scrollToTop} $isVisible={isVisible}>
      <img src={TopBtn} alt="Go to top" />
    </TopButton>
  );
};

export default ScrollToTopButton;

const TopButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  display: ${({ $isVisible }) => ($isVisible ? "block" : "none")};
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  & > img {
    width: 75px;
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 440px) {
      width: 55px;
    }
  }
`;
