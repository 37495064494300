import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import ScreenMirroring from "assets/images/screenmirroring.png";
import ScreenMirroringMobile from "assets/images/screenmirroring_mobile.png";
import Blended from "assets/images/blended.png";
import BlendedMobile from "assets/images/blended_mobile.png";
import TeachingTool from "assets/images/teachingtool.png";
import TeachingToolMobile from "assets/images/teachingtool_mobile.png";
import { useTranslation } from "react-i18next";
import useResponsive from "hooks/useResponsive";

const Smart4 = () => {
  const { t } = useTranslation();

  const { isSmartMobile } = useResponsive();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          <span style={{ color: "#0C224F" }}>{t("key")}</span>&nbsp;
          {t("services")}
        </h3>
        <FlexOutBox>
          <FlexBox>
            {isSmartMobile ? (
              <img src={ScreenMirroringMobile}></img>
            ) : (
              <img src={ScreenMirroring}></img>
            )}
            <div className="description">
              <h4>
                1.<br></br>
                {t("key-services-title1")}
              </h4>
              <p>{t("key-services-text1")}</p>
            </div>
          </FlexBox>
          <FlexBox>
            {isSmartMobile ? (
              <img src={BlendedMobile}></img>
            ) : (
              <img src={Blended}></img>
            )}
            <div className="description">
              <h4>
                2.<br></br>
                {t("key-services-title2")}
              </h4>
              <p>{t("key-services-text2")}</p>
            </div>
          </FlexBox>
          <FlexBox>
            {isSmartMobile ? (
              <img src={TeachingToolMobile}></img>
            ) : (
              <img src={TeachingTool}></img>
            )}
            <div className="description">
              <h4>
                3.<br></br>
                {t("key-services-title3")}
              </h4>
              <p>{t("key-services-text3")}</p>
            </div>
          </FlexBox>
        </FlexOutBox>
      </div>
    </Section>
  );
};

export default Smart4;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 100px 24px;
  @media screen and (max-width: 1024px) {
    padding: 50px 24px;
  }

  & > .wrapper {
    width: 100%;
    max-width: 1117px;
    margin: auto;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
    }
    & > h3 {
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      margin-bottom: 80px;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        margin-bottom: 40px;
        font-size: 30px;
        height: auto;
        white-space: normal;
      }
      @media screen and (max-width: 660px) {
        margin-bottom: 20px;

        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      color: #141414;
      font-size: 26px;
      line-height: 43px;
      margin-bottom: 80px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 25px;
    }
    @media screen and (max-width: 660px) {
    }
  }
`;

const FlexOutBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FlexBox = styled.div`
  width: 325px;
  @media screen and (max-width: 1024px) {
    width: 224px;
  }
  @media screen and (max-width: 725px) {
    width: 100%;
    margin-bottom: 20px;
  }
  & > img {
    width: 100%;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }
  }
  & > .description {
    & > h4 {
      color: #141414;
      font-size: 26px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
      white-space: pre-line;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
      @media screen and (max-width: 660px) {
        margin-bottom: 4px;
        font-size: 16px;
      }
    }
    & > p {
      color: #666;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
      }
      @media screen and (max-width: 660px) {
        line-height: 22px;
      }
    }
  }
`;
