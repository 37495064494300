import React from "react";
import styled from "styled-components";
import Welcome1En from "assets/images/welcome1-en.png";
import Welcome1Vi from "assets/images/welcome1-vi.png";
import { useTranslation } from "react-i18next";
import { useLangContext } from "context/LangContext";

const Welcome1 = () => {
  const { t } = useTranslation();

  const { language } = useLangContext();

  return (
    <Section>
      <div className="wrapper">
        <h3 data-aos="fade-up">
          {t("introducing-kgo")}&nbsp;
          <span style={{ color: "#0C224F" }}>KGO</span>
        </h3>
        <p data-aos="fade-up">{t("introducing-kgo-text")}</p>
        <div className="imgBox" data-aos="fade-up">
          {language == "vi" && <img src={Welcome1Vi}></img>}
          {language == "en" && <img src={Welcome1En}></img>}
        </div>
      </div>
    </Section>
  );
};

export default Welcome1;

const Section = styled.section`
  width: 100%;
  background: url(${(props) => props.$bg});
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding: 0 24px;
  margin-bottom: 100px;
  & > .wrapper {
    width: 100%;
    max-width: 954px;
    margin: auto;
    @media screen and (max-width: 1024px) {
      max-width: 733px;
    }
    @media screen and (max-width: 660px) {
    }
    & > h3 {
      margin-top: 100px;
      color: #141414;
      font-size: 54px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      white-space: nowrap;
      text-transform: uppercase;
      @media screen and (max-width: 1024px) {
        margin-top: 80px;
        font-size: 30px;
        height: auto;
      }
      @media screen and (max-width: 660px) {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
        height: auto;
      }
    }
    & > p {
      color: #141414;
      font-size: 26px;
      line-height: 43px;
      margin-bottom: 80px;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 660px) {
        margin-bottom: 20px;
      }
    }
    & > .imgBox {
      & > img {
        width: 100%;
        margin-bottom: 40px;
      }
      & > .list {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        color: #141414;
        font-size: 16px;
        & > img {
          margin-right: 10px;
        }
      }
    }
  }
`;
